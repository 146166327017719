import React, { useEffect, useState } from "react";
import Header from "../../components/bloks/header/Header";
import Footer from "../../components/bloks/footer/Footer";
import Dropdown from "../../components/dropdown/Dropdown";
import BasicTabs from "../../components/tabs/BasicTabs";
import {
  OutlinedInput,
  FormControlLabel,
  Switch,
  Alert,
  Snackbar,
} from "@mui/material";
import { faStarOfLife } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import { AXIOS_GET } from "../../components/api";
import {
  FETCH_CITIES_API_URL,
  FETCH_DISTRICTS_API_URL,
  FETCH_RETAILERS_STORE_CAMPAIGNS_URL,
  FETCH_STATES_API_URL,
} from "./constants";
import PromoionOffers from "./PromoionOffers";
import Loader from "../../components/Utils/loader/Loader";
import "./locateStore.css";

const locateStorePromoCard =
  "/images/locate-store/locate_retailer_store_image.png";
const noStoreFoundImg = "/images/no-stores-found.png";

const pageSize = process.env.REACT_APP_DATA_LOAD_PAGE_SIZE;

function LocateStore() {
  const [stateDropdownValue, setStateDropdownValue] = useState(null);
  const [cityDropdownValue, setCityDropdownValue] = useState(null);
  const [districtDropdownValue, setDistrictDropdownValue] = useState(null);
  const [storeNameSearch, setStoreNameSearch] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [activePromosSwitch, setActivePromosSwitch] = useState(false);
  const [storesPromos, setStoresPromos] = useState([]);
  const [hasMoreStores, setHasMoreStores] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [storePageIndex, setStorePageIndex] = useState(1);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Locate Store";
  }, []);

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    // countryCode=1 for Nigeria
    const stateResponse = await AXIOS_GET(`${FETCH_STATES_API_URL}1`);
    setStates(stateResponse?.Data);
  };

  const getDistricts = async (item) => {
    const districtResponse = await AXIOS_GET(
      `${FETCH_DISTRICTS_API_URL}${item.Id}`
    );
    setDistricts(districtResponse?.Data || []);
  };

  const getCities = async (item) => {
    const citiesResponse = await AXIOS_GET(`${FETCH_CITIES_API_URL}${item.Id}`);
    setCities(citiesResponse?.Data || []);
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  const onLocationNameSearchChange = ({ target }) => {
    setStoreNameSearch(target.value);
  };

  const onSearchBtnClick = async (input, paginate = false) => {
    let pageIndex = storePageIndex;
    setIsLoading(true);
    if (paginate && storesPromos.length > 0) {
      pageIndex = pageIndex + 1;
      setStorePageIndex(pageIndex);
    } else {
      setHasMoreStores(true);
      pageIndex = 1;
      setStorePageIndex(pageIndex);
    }
    let queries = {
      CountryId: 1, // 1 for Nigeria
      PageSize: pageSize,
      PageIndex: pageIndex,
      HasActiveCampaign: Number(activePromosSwitch),
    };
    if (activeTab === 0) {
      // this is for location search btn click by choosing state, district and city
      queries = {
        ...queries,
        StateId: stateDropdownValue?.Id,
        Lga: districtDropdownValue?.Id, // district
        City: cityDropdownValue?.Id,
      };
    } else {
      // this is store name search
      queries.StoreName = storeNameSearch;
    }
    const stringifiedQueries = queryString.stringify(queries);
    const storePromsResponse = await AXIOS_GET(
      `${FETCH_RETAILERS_STORE_CAMPAIGNS_URL}?${stringifiedQueries}`
    );
    if (storePromsResponse.error) {
      setErrorMessage(storePromsResponse.error?.message);
      setIsLoading(false);
      return;
    }
    setErrorMessage(null);
    if (paginate) {
      let stores = storesPromos.concat(storePromsResponse?.Data?.Retailers);
      if (stores.length < storePromsResponse?.Data?.TotalRecordCount) {
        setHasMoreStores(true);
      } else {
        setHasMoreStores(false);
      }
      setStoresPromos(stores);
    } else {
      setStoresPromos(storePromsResponse?.Data?.Retailers || []);
    }
    setTotalRecordCount(storePromsResponse?.Data?.TotalRecordCount || 0);
    setIsLoading(false);
  };

  const onStateDropdownChange = (item) => {
    getDistricts(item);
  };

  const onDistrictDropdownChange = (item) => {
    getCities(item);
  };

  const onActivePromsSwitchChange = ({ target }) => {
    setActivePromosSwitch(target.checked);
  };

  useEffect(() => {
    onSearchBtnClick();
  }, [activePromosSwitch]);

  const onLocationNameSearchKeyClick = (e) => {
    // key code 13 = "Enter key"
    if (e.keyCode == 13) {
      onSearchBtnClick();
    }
  };

  const locationSearchView = () => {
    return (
      <div>
        <Dropdown
          label="State"
          required={true}
          setValue={setStateDropdownValue}
          onDropDownChange={onStateDropdownChange}
          valueKey={"Id"}
          nameKey={"Name"}
          selectedItem={stateDropdownValue}
          dropdownValues={states}
        />
        {/* Districts dropdown */}
        {stateDropdownValue && (
          <div className="dropdown_container">
            <Dropdown
              label="LGA"
              valueKey={"Id"}
              nameKey={"Name"}
              required={true}
              onDropDownChange={onDistrictDropdownChange}
              setValue={setDistrictDropdownValue}
              selectedItem={districtDropdownValue}
              dropdownValues={districts}
            />
          </div>
        )}
        {/* Cities dropdown */}
        {districtDropdownValue && cities.length > 0 && (
          <div className="dropdown_container">
            <Dropdown
              label="LCDA"
              valueKey={"Id"}
              nameKey={"Name"}
              required={true}
              setValue={setCityDropdownValue}
              selectedItem={cityDropdownValue}
              dropdownValues={cities}
            />
          </div>
        )}
      </div>
    );
  };

  const locationNameSearchView = () => {
    return (
      <>
        <div className="title_labels">
          Store Name{" "}
          <FontAwesomeIcon
            width={6}
            height={6}
            color="#D10101"
            icon={faStarOfLife}
          />
        </div>
        <OutlinedInput
          fullWidth
          style={{ background: "white" }}
          placeholder="Louis Enterprise"
          id="filled-basic"
          value={storeNameSearch}
          onKeyDown={onLocationNameSearchKeyClick}
          onChange={onLocationNameSearchChange}
          aria-describedby="outlined-weight-helper-text"
        />
      </>
    );
  };

  return (
    <div>
      <Header logo={"./images/logo.png"} />
      {/* sub-Header with  background image */}
      <section>
        <div className="locate-store-bg">
          <div className="heading_div">
            <div className="myRetailStoreHeading">My Store Retailers</div>
            <div className="locateRetailerSubHeading">Locate Retailers</div>
          </div>
        </div>
      </section>

      {/* Locate retailer first section */}
      <section>
        <div className="container-fluid location_search_img_row">
          <div className="steper_row locationSearchRow">
            <div className="steper_grid locationSearchCol">
              <BasicTabs
                setValue={setActiveTab}
                tabs={[
                  {
                    label: "Location Search",
                    component: locationSearchView(),
                  },
                  {
                    label: "Store Name Search",
                    component: locationNameSearchView(),
                  },
                ]}
              />
              <div className="searchBtnDiv">
                <button onClick={onSearchBtnClick} className="searchBtn">
                  Search
                </button>
              </div>
            </div>
            <div className="steper_grid locationImgCol">
              <div>
                <div className="locate_store_img">
                  <img
                    className="locate_store_img"
                    src={locateStorePromoCard}
                    alt="locate_store-retail"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Promotion offfer section */}
      <section className="promotion_sec">
        <div className="activePromoSwitchContainer">
          <FormControlLabel
            control={
              <Switch
                onChange={onActivePromsSwitchChange}
                checked={activePromosSwitch}
              />
            }
            label="Active Promos"
            labelPlacement="start"
          />
        </div>
        {totalRecordCount > 0 && (
          <div className="container-fluid stores_found_div">
            {`(${totalRecordCount})`} Stores found
          </div>
        )}
        {isLoading ? (
          <div className="container-fluid loadingg_div">
            <Loader isSectionLoader show={isLoading} />
            Searching for stores
          </div>
        ) : storesPromos.length === 0 ? (
          <div className="container-fluid loadingg_div">
            <img className="" src={noStoreFoundImg} alt="noStoresfoundImg" />
            No stores found
          </div>
        ) : null}
        <PromoionOffers
          onPaginate={onSearchBtnClick}
          hasMore={hasMoreStores}
          promos={storesPromos}
        />
      </section>
      <Footer />
      {errorMessage && (
        <Snackbar
          open={errorMessage}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={() => {
            setErrorMessage(null);
          }}
        >
          <Alert
            severity="error"
            onClose={() => {
              setErrorMessage(null);
            }}
          >
            {errorMessage}{" "}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export default LocateStore;
