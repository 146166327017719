import axios from "axios";

const host_url = "https://devomsapi.omnibiz.app";

export const POST = async (requestUrl, requestData, headers) => {
  let errorCode;
  try {
    //this is where the problem is
    // const requestHeaders = headers || (await localForage.getItem("jsonHeader"));
    // if (!requestHeaders) {
    // 	throw new Error("An error occured. Please try again");
    // }

    const response = await axios.post(
      `${host_url}${requestUrl}`,
      requestData,
      null
    );
    if (response.data.StatusCode === 1) {
      // console.log(response.data);
      return response.data;
    } else {
      errorCode = response.data.StatusCode;
      if (
        response.data.ErrorMessage &&
        response.data.ErrorMessage.toLowerCase() !== "internal server error"
      ) {
        throw new Error(response.data.ErrorMessage);
      } else {
        throw new Error("An error occured. Please try again");
      }
    }
  } catch (error) {
    return {
      error: {
        message:
          error.message === "Network Error"
            ? "Please check your internet connection and try again"
            : error.message,
        errorCode,
      },
    };
  }
};

export const AXIOS_GET = async (requestUrl) => {
  let errorCode;
  try {
    const response = await axios.get(requestUrl, null);
    if (response.data.StatusCode === 1 || response.data.StatusCode === "OK") {
      // console.log(response.data);
      return response.data;
    } else {
      errorCode = response.data.StatusCode;
      if (
        response.data.ErrorMessage &&
        response.data.ErrorMessage.toLowerCase() !== "internal server error"
      ) {
        throw new Error(response.data.ErrorMessage);
      } else {
        throw new Error("An error occured. Please try again");
      }
    }
  } catch (error) {
    return {
      error: {
        message:
          error.message === "Network Error"
            ? "Please check your internet connection and try again"
            : error.message,
        errorCode,
      },
    };
  }
};
