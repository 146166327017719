import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";

const promoGiftCard = "/images/locate-store/promo-gift-card.png";

const PromoionOffers = (props) => {
  const { promos, onPaginate, hasMore } = props;
  const [selectedStore, setSelectedStore] = useState(promos?.[0] ?? null);

  useEffect(() => {
    setSelectedStore(promos?.[0] ?? null);
  }, [promos]);

  const onCouponcodeClick = (CouponCode) => {
    navigator.clipboard.writeText(CouponCode || "");
  };

  const getPromoListItemCard = (store, index) => {
    return (
      <div
        key={`list_store_key_${index}`}
        className={
          selectedStore?.RetailerId === store.RetailerId
            ? "prom_list_item_card_selected"
            : "prom_list_item_card"
        }
        onClick={() => {
          setSelectedStore(store);
        }}
      >
        <div className="promo_list_item_title">{store.StoreName}</div>
        <div className="promo_list_item_description">{store.StoreAddress}</div>
        <div className="promo_list_item_use_today_container">
          {" "}
          <div className="promo_list_item_use_today">
            {store?.TotalUsed} uses today
          </div>
        </div>
        {selectedStore?.RetailerId === store.RetailerId &&
          storePromotionsSecondColView(true)}
      </div>
    );
  };

  const storeCampaignCard = (campaign, index) => {
    const getTemplateBasedCampaignDescription = () => {
      let view = null;
      switch (campaign.CashBackValueType) {
        case 2:
          //  Value off- "₦50 when you buy 1 pack of Gino rice" (don't mention SKU full name)
          view = (
            <div>
              <span className="offer_percent">₦{campaign?.Discount}</span>{" "}
              <span className="offer_percent_postfix_text">
                off when you buy {campaign?.Quantity} {campaign?.UOM} {" of "}{" "}
                {campaign?.SkuName}
              </span>
            </div>
          );
          break;
        case 1:
          // Percentage off- "20% off when you buy 1 pack of Gino rice" (don't mention SKU full name)
          view = (
            <div>
              <span className="offer_percent">{campaign?.Discount}%</span>{" "}
              <span className="offer_percent_postfix_text">
                off when you buy {campaign?.Quantity} {campaign?.UOM} {" of "}{" "}
                {campaign?.SkuName}
              </span>
            </div>
          );
          break;
        case 4:
          // Same SKU Back- "Buy 3 Gino Rice & Get 1 free"
          view = (
            <div>
              {/* <span className="offer_percent"></span>{" "} */}
              <span className="offer_percent_postfix_text">
                Buy {campaign?.Quantity} {campaign?.SkuName} & Get{" "}
                {campaign?.RewardQuantity} free
              </span>
            </div>
          );
          break;
        case 3:
          // Different SKU Back- "Buy 3 Gino Rice & Get 1 Lays Chips free"
          view = (
            <div>
              {/* <span className="offer_percent"></span>{" "} */}
              <span className="offer_percent_postfix_text">
                Buy {campaign?.Quantity} {campaign?.SkuName} & Get{" "}
                {campaign?.RewardQuantity} {campaign?.RewardSkuName}
              </span>
            </div>
          );
          break;
        default:
          view = null;
      }
      return view;
    };

    return (
      <div
        key={`store_campaign_${index}`}
        className="promos_detail_each_offer_card"
      >
        <div className="promo_detail_row">
          <div>
            <div className="offer_brand_title">{campaign?.CampaignName}</div>
            {/* <div>
              <span className="offer_percent">{campaign?.Discount}</span>{" "}
              <span className="offer_percent_postfix_text">
                off when you buy {campaign?.Quantity} {campaign?.UOM} {" of "}{" "}
                {campaign?.SkuName}
              </span>
            </div> */}
            {getTemplateBasedCampaignDescription()}
          </div>
          <div className="offer_code_col">
            {campaign?.CouponCode && (
              <div
                onClick={() => {
                  onCouponcodeClick(campaign?.CouponCode);
                }}
                className=""
              >
                <span className="promo_code_text"> {campaign?.CouponCode}</span>
                <FontAwesomeIcon type="light" color="#015FD1" icon={faCopy} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const getEmptyCampaignView = () => {
    if (selectedStore?.Campaigns?.length === 0) {
      return <div className="no_campaigns_container">No Campaigns</div>;
    }
    return null;
  };

  const storePromotionsSecondColView = (showUnderList = false) => {
    if (!selectedStore) {
      return null;
    }
    return (
      <div
        className={
          showUnderList ? "promos_detail_col_under_list" : "promos_detail_col"
        }
      >
        <div className="promos_detail_heading_row">
          <div>
            <div className="promo_title">Promotions</div>
            <div className="promo_code_offer_title">Promo Code Offer</div>
          </div>
          <div>
            <img
              className="prom_gift_card_img"
              src={promoGiftCard}
              alt="promoGiftCard"
            />
          </div>
        </div>
        {/* list of offers of the stores  */}
        {selectedStore?.Campaigns?.map?.(storeCampaignCard)}
        {getEmptyCampaignView()}
      </div>
    );
  };

  const fetchMoreStoresData = () => {
    onPaginate(null, true);
  };

  return (
    <div className="promo_container">
      <div className="container-fluid">
        <div className="steper_row">
          {/* first column - stores list with pagination */}
          <div className="promos_list_col">
            <InfiniteScroll
              dataLength={promos.length}
              next={fetchMoreStoresData}
              hasMore={hasMore}
            >
              {promos?.map?.(getPromoListItemCard)}
            </InfiniteScroll>
          </div>
          {/* second column - promotion detail column */}
          {storePromotionsSecondColView()}
        </div>
      </div>
    </div>
  );
};

export default PromoionOffers;
