import React from "react";

function VideoModal({ closeModel, videolink, modalid }) {
    return (
        <div>
            <div className="modal" id={modalid}>
                <div className="modal__content largemodal">
                    <div className="modal__wrapper">
                        <a
                            href="#return"
                            onClick={() => {
                                closeModel(false);
                            }}
                            className="modal__close">
                            ✕
                        </a>
                        <iframe title="new"  width="100%" height="515" src={videolink} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VideoModal;
