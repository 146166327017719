import React from "react";
import './SolutionFormStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


function ManufacturersForm() {



  return (
    <div>

      <div className="modal" id="ManufacturersForm">
        <div className="modal__content form_modal">   
          <div className="modal__wrapper">
            <a href="#return" className="modal__close">✕</a>
            <div className="modal_form">
              <div className="Modal_form_hdng">
                <h2>Partner with us</h2>
                <p>Please provide all required details to register your business with us</p>
              </div>

              <form className="modalform_wrapper" method="post" action="https://submit-form.com/6HiTJy1e">
                <h4 className="hdng">Business owner</h4>
                <div className="input-group left_input required">
                  <label>First name</label>
                  <input type="text" name="firstName" required/>
                </div>
                <div className="input-group right_input required">
                  <label>Last name</label>
                  <input type="text" name="lastName" required/>
                </div>

                <div className="input-group required">
                  <label>Business name</label>
                  <input type="text" name="businessName" required />
                </div>

                <div className="input-group left_input required">
                  <label>Contact number</label>
                  <input type="text" placeholder="0801-234-5678" name="phoneNo" required/>
                </div>
                <div className="input-group right_input">
                  <label>Email</label>
                  <input type="text" name="emailAddress" />
                </div>
                <h4 className="hdng">Address</h4>
                <div className="input-group required">
                  <label>Street Address</label>
                  <input type="text" name="addressLine1" required />
                </div>
                <div className="input-group">
                  <label>Street Address Line 2</label>
                  <input type="text" name="addressLine2" />
                </div>
                <div className="input-group required">
                  <label>State</label>
                  <input type="text" name="state"  required/>
                </div>
                <div className="input-group left_input required">
                  <label>LGA</label>
                  <input type="text" name="lga" required />
                </div>
                <div className="input-group right_input required">
                  <label>City</label>
                  <input type="text" name="city" required />
                </div>
                <div className="input-group">
                  <h4 className="hdng">Business Website</h4>
                  <input type="text" name="website" />
                </div>
                <div className="input-group ">
                  <h4 className="hdng">Category of Products</h4>
                  <label className="checkbox"><input type="checkbox" id="vehicle1" name="food" value="Bike" /> Food</label>
                  <label className="checkbox"><input type="checkbox" id="vehicle1" name="beverage" value="Bike" /> Beverage</label>
                  <label className="checkbox"><input type="checkbox" id="vehicle1" name="personal_care" value="Bike" /> Personal Care</label>
                  <label className="checkbox"><input type="checkbox" id="vehicle1" name="pharmaceutical" value="Bike" /> Pharmaceutical</label>
                  <label className="checkbox"><input type="checkbox" id="vehicle1" name="other" value="Bike" /> Other</label>
                  <input type="text" placeholder="Type other category of products" name="otherProducts" />
                </div>
                <div className="input-group required" style={{ marginTop: "20px" }}>
                  <label>Which location(s) are you present in?</label>
                  <input type="text" name="locations" required />
                </div>
                <div className="input-group">
                  <label>Message</label>
                  <textarea name="message"></textarea>
                </div>
                <div className="input-droup">
                  <button type="submit" className="submit">Continue <FontAwesomeIcon icon={faArrowRight} /></button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>

    </div>

  )
}

export default ManufacturersForm