import React from "react";
import "./TeamProfile-Style.css";
import { FaLinkedin } from "react-icons/fa";

function Team({ image, name, designation, linkedin }) {
  return (
    <div>
      <a href={linkedin} itemProp={linkedin} target="_blank" rel = "noreferrer" className="team">
        <div className="img_link">
          <img itemProp="image" src={image} alt="" />
          <i className="linkedin" target="_blank">
            <FaLinkedin />
          </i>
        </div>
        <span itemProp="name" className="name">
          {name}
        </span>
        <span itemProp="designation" className="designation">
          {designation}
        </span>
      </a>
    </div>
  );
}

export default Team;
