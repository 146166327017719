import React, { useEffect } from "react";
import "./ManufacturersStyle.css";
import Header from "../../components/bloks/header/Header";
import Footer from "../../components/bloks/footer/Footer";
import OuterBanner from "../../components/outer-page-banner/OuterBanner";
import BannerCard from "../../components/banner-card/BannerCard";
import Steper from "../../components/steper/Steper";
import SteperImage from "../../components/steper/SteperImage";
import Distribution from "../../components/distribution/Distribution";
import OmnibizSolutions from "../../components/omnibiz-solutions/OmnibizSolutions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import GroupBrands from "../../assets/images/group-brands.png";
import { Link } from "react-router-dom";
import SignupModal from "../../components/signup-modal/SignUpModal";

function Manufacturers() {
  useEffect(() => {
    document.title = "Manufacturers";
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div>
      <Header logo={"./images/logo.png"} />

      <OuterBanner backgroundImg={"url(images/manufacturers-banner.png)"} />
      <BannerCard
        backgroundColor={"linear-gradient(to right, #f95b2f, #f44d21)"}
        icon={"./images/chimni.svg"}
        forOmni={"OmniBiz for"}
        heading={"Manufacturers"}
        describe={
          "OmniBiz makes it easy for you to gain shelf visibility for your range of products "
        }
        button={"Sign up now"}
        videoPlay={"none"}
      />

      <section className="steper_sec">
        <div className="container">
          <div className="steper_row">
            <div className="steper_grid AAA">
              <SteperImage image={"/images/stepermap.png"} />
            </div>
            <div className="steper_grid BBB">
              <Steper
                title={"DISTRIBUTION"}
                heading={"Reach deeper markets like never before"}
                description={
                  "Get your products into the wider markets. OmniBiz makes it easier for consumers to discover and adopt your range of products."
                }
              />
            </div>
          </div>

          <div className="steper_row">
            <div className="steper_grid BBB">
              <Steper
                title={"ANALYTICS"}
                heading={"Realtime Data for informed decisions"}
                description={
                  "Make better and smarter decisions with our robust dashboard for managing and monitoring market performance."
                }
              />
            </div>
            <div className="steper_grid AAA">
              <SteperImage image={"/images/manuanalytics.png"} />
            </div>
          </div>
        </div>
      </section>

      <section
        className="joinOver_holder"
        style={{ height: "300px", marginBottom: "270px" }}
      >
        <div className="conteiner">
          <div className="joinOver_card">
            <h3 className="joinOver_h3js">Join Over</h3>
            <span className="joinOver_hightlight">300 Brands</span>
            <h3 className="joinOver_h3js">That trust and work with us.</h3>
            <div className="grp_brands">
              <img src={GroupBrands} alt="" />
            </div>
            <div className="joinOver_actions">
              <a href="#signup" className="signup">
                Sign up now <FontAwesomeIcon icon={faArrowRight} />
              </a>
              <Link to="/contact-us" className="contact">
                Contact us <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Distribution />

      <section className="over_auto solutions_row">
        <div className="solutions_item3colom">
          <OmnibizSolutions
            image={"images/distributors-bg.png"}
            solutionsfor={"OmniBiz for"}
            backgroundColor={"rgb(1 16 80 / 80%)"}
            title={"Distributors"}
            seebtn={"/distributors"}
          />
        </div>
        <div className="solutions_item3colom">
          <OmnibizSolutions
            image={"images/logistics-solution.png"}
            backgroundColor={"rgb(109 29 96 / 80%)"}
            solutionsfor={"OmniBiz for"}
            title={"Logistics"}
            seebtn={"/logistics"}
          />
        </div>

        <div className="solutions_item3colom">
          <OmnibizSolutions
            image={"images/retailers-solutions.png"}
            backgroundColor={"rgb(18 124 66 / 80%)"}
            solutionsfor={"OmniBiz for"}
            title={"Retailers"}
            seebtn={"/retailers"}
          />
        </div>
      </section>

      <Footer />
      <SignupModal />
    </div>
  );
}

export default Manufacturers;
