import "./conactus/ContactUs.css";
import Header from "../components/bloks/header/Header";
import Footer from "../components/bloks/footer/Footer";
import OmnibizSolutions from "../components/omnibiz-solutions/OmnibizSolutions";
import SignupModal from "../components/signup-modal/SignUpModal";
import { useState } from "react";
import Alert from '@mui/material/Alert';
import { POST } from "../components/api";
import { Link } from "react-router-dom";

const BMPLsignup = () => {
    const [fullName, setFullName] = useState("");
    const [storeName, setStoreName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [location, setLocation] = useState("");
    const [error, setError] = useState({err:0, errMessage:""});
    const [valfullName, setValfullName] = useState(0);
    const [valstoreName, setValstoreName] = useState(0);
    const [valphoneNumber, setValphoneNumber] = useState(0);
    const [vallocation, setVallocation] = useState(0);
    const [success, setSuccess] = useState(false);

    const handleChange = (e, field) => {
        if(field === "fullname"){
            setFullName(e.target.value);
        }
        if(field === "storename"){
            setStoreName(e.target.value);
        }
        if(field === "phoneno"){
            setPhoneNumber(e.target.value);
        }
        if(field === "location"){
            setLocation(e.target.value);
        }
    };


    const validateUser = () => {
        fullName === ""? setValfullName(1):setValfullName(0);
        storeName === ""? setValstoreName(1):setValstoreName(0);
        phoneNumber === ""? setValphoneNumber(1):setValphoneNumber(0);
        location === ""? setVallocation(1):setVallocation(0);
    };

    const updateContact = async() => {
        validateUser();
        const data = {
            FullName: fullName,
            StoreName: storeName,
            PhoneNo: phoneNumber,
            Location: location,
        }
        const res = await POST("/api/onboarding/bnpl", data);
        if(res.ErrorMessage !== "Registration successful"||null){
            setError({
                err:1,
                errMessage: res.ErrorMessage,
            })
        }
        else{
            setError({
                err:0,
                errMessage: "",
            })
        };
        setSuccess(true);
    };

    const handleSubmit = (e) =>{
        e.preventDefault();
        updateContact();
    }

    return (
        <div>
            <Header logo={"./images/logo.png"} />

            <section className="contactus_sec">
                <div className="container">
                    <div className="contact_wrapper">
                        <div className="col_left">
                            <div className="heading"> 
                                <h1>
                                    Register your interest on OmniBiz BMPL
                                </h1>
                                <p>Want to avail benefits of our Buy More Pay Later feature?
                                Register your interest now and our team will get in touch with you!</p>
                            </div>
                            <form method="post" className="contact_form">
                                <div className="form_droup">
                                {valfullName ? <span style={{color:'red'}}>Full Name cannot be empty</span> : null}
                                    <input type="text" placeholder="Full Name" required="required"
                                    onChange={e => handleChange(e, "fullname")} />
                                   
                                </div>
                                <div className="form_droup">
                                {valstoreName ? <span style={{color:'red'}}>Store Name cannot be empty</span> : null}
                                    <input type="text" placeholder="Store Name" required="required"
                                    onChange={e => handleChange(e, "storename")} />
                                </div>
                                <div className="form_droup">
                                {valphoneNumber ? <span style={{color:'red'}}>Phone Number cannot be empty</span> : null}
                                {error.err ? <span style={{color:'red'}}>{error.errMessage}</span> : null}
                                    <input type="text" placeholder="Phone Number" required="required"
                                    onChange={e => handleChange(e, "phoneno")} />
                                </div>
                                <div className="form_droup">
                                {vallocation ? <span style={{color:'red'}}>Location cannot be empty</span> : null}
                                    <input type="text" placeholder="Location" required="required"
                                    onChange={e => handleChange(e, "location")} />
                                </div>
                                <p />
                                <div className="form_droup">
                                    <button type="button" onClick={e => handleSubmit(e)}>
                                        Register
                                    </button>
                                </div>
                            </form>
                            <p />
                            {fullName !== "" && 
                            storeName !== "" &&
                            phoneNumber !== "" &&
                            location !== "" &&
                            error.err === 0 && success && 
                                <Alert severity="success">Thank you for signing up -
                                Our team will get in touch with you soon. 
                                Click <Link to ="/">here</Link> to return to the home page.</Alert>}
                        </div>
                    </div>
                </div>
                
            </section>

            <section>
                <div className="solutions_row">
                    <div className="solutions_item4colom">
                        <OmnibizSolutions image={"images/manufactur.png"} backgroundColor={"rgb(231 38 53 / 75%)"} solutionsfor={"OmniBiz for"} title={"Manufacturers"} seebtn={"/manufacturers"} />
                    </div>

                    <div className="solutions_item4colom">
                        <OmnibizSolutions image={"images/distributors-bg.png"} solutionsfor={"OmniBiz for"} backgroundColor={"rgb(1 16 80 / 80%)"} title={"Distributors"} seebtn={"/distributors"} />
                    </div>

                    <div className="solutions_item4colom">
                        <OmnibizSolutions image={"images/logistics-solution.png"} backgroundColor={"rgb(109 29 96 / 80%)"} solutionsfor={"OmniBiz for"} title={"Logistics"} seebtn={"/logistics"} />
                    </div>

                    <div className="solutions_item4colom">
                        <OmnibizSolutions image={"images/retailers-solutions.png"} backgroundColor={"rgb(18 124 66 / 80%)"} solutionsfor={"OmniBiz for"} title={"Retailers"} seebtn={"/retailers"} />
                    </div>
                </div>
            </section>
            <Footer />
            <SignupModal />
        </div>
    );
}

export default BMPLsignup;