import React from "react";
import "./dropdown.css";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { faStarOfLife } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Dropdown = (props) => {
  const {
    label,
    dropdownValues,
    required,
    setValue,
    selectedItem,
    valueKey = "value",
    nameKey = "name",
    placeeHolder = "",
    onDropDownChange,
  } = props;

  const handleChange = (item) => {
    const itemSelected = dropdownValues.filter(
      (data) => data[valueKey] === item.target.value
    )?.[0];
    setValue(itemSelected);
    if (onDropDownChange) {
      onDropDownChange(itemSelected);
    }
  };

  return (
    <div>
      <InputLabel className="title_labels">
        {label}{" "}
        {required && (
          <FontAwesomeIcon
            width={6}
            height={6}
            color="#D10101"
            icon={faStarOfLife}
          />
        )}
      </InputLabel>
      <FormControl fullWidth>
        <Select
          className="dropdownSelect"
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedItem?.[valueKey] ?? " "}
          placeholder={placeeHolder}
          onChange={handleChange}
        >
          {dropdownValues?.map?.((item) => (
            <MenuItem
              key={`item_${item[valueKey]}`}
              onChange={handleChange}
              value={item[valueKey]}
            >
              {item[nameKey]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Dropdown;
