import React from "react";
import "./SolutionFormStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function DistributorsForm() {
    return (
        <div>
            <div className="modal" id="DistributorsForm">
                <div className="modal__content form_modal">
                    <div className="modal__wrapper">
                        <a href="#return" className="modal__close">
                            ✕
                        </a>
                        <div className="modal_form">
                            <div className="Modal_form_hdng">
                                <h2>Partner with us</h2>
                                <p>Please provide all required details to register your business with us</p>
                            </div>

                            <form className="modalform_wrapper" method="post" action="https://submit-form.com/LC4qhNN5">
                                <h4 className="hdng">Business owner</h4>
                                <div className="input-group left_input required">
                                    <label >First name</label>
                                    <input type="text" name="distributorFirstName" required />
                                </div>
                                <div className="input-group right_input required">
                                    <label>Last name</label>
                                    <input type="text" name="distributorLastName" required />
                                </div>

                                <div className="input-group required">
                                    <label>Business name</label>
                                    <input type="text" name="distributorBusinessName" required/>
                                </div>

                                <div className="input-group left_input required">
                                    <label>Contact number</label>
                                    <input type="text" placeholder="0801-234-5678" name="distributorContactNumber" required/>
                                </div>
                                <div className="input-group right_input">
                                    <label>Email</label>
                                    <input type="text" name="distributorEmail" />
                                </div>
                                <h4 className="hdng">Address</h4>
                                <div className="input-group required">
                                    <label>Street Address</label>
                                    <input type="text" name="distributorAddressLine1" required/>
                                </div>
                                <div className="input-group">
                                    <label>Street Address Line 2</label>
                                    <input type="text" name="distributorAddressLine2" />
                                </div>
                                <div className="input-group required">
                                    <label>State</label>
                                    <input type="text" name="distributorState" required/>
                                </div>
                                <div className="input-group left_input required">
                                    <label>LGA</label>
                                    <input type="text" name="distributorLGA" required/>
                                </div>
                                <div className="input-group right_input required">
                                    <label>City</label>
                                    <input type="text" name="distributorCity" required/>
                                </div>
                                <div className="input-group required">
                                    <label>Which all companies are you a distributor of?</label>
                                    <input type="text" name="distributorAssociatedCompanies" required/>
                                </div>
                                <div className="input-group">
                                    <label>Kindly provide your business' registered TIN number</label>
                                    <input type="text" name="distributorTinNo"/>
                                </div>
                                <div className="input-group required">
                                    <label>Kindly provide the registration number on your CAC certificate</label>
                                    <input type="text" name="distributorCacNo" required/>
                                </div>
                                <div className="input-droup">
                                    <button className="submit">
                                        Continue <FontAwesomeIcon icon={faArrowRight} />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DistributorsForm;
