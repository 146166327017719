import React from "react";
import "./OuterBannerStyle.css";

function OuterBanner({ backgroundImg }) {
    return (
        <div>
            <section className="outer_banner" itemProp="backgroundImg" backgroundImg={backgroundImg} style={{ background: backgroundImg }}></section>
        </div>
    );
}

export default OuterBanner;
