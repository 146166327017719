import React, { useEffect } from "react";
import "./Terms&Conditions.css";
import Header from "../../components/bloks/header/Header";
import Footer from "../../components/bloks/footer/Footer";
import OmnibizSolutions from "../../components/omnibiz-solutions/OmnibizSolutions";
import SignUpModal from "../../components/signup-modal/SignUpModal";

function TermsConditions() {
    useEffect(() => {
        document.title = "Terms & Conditions";
    }, []);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            // behavior: 'smooth'
        });
    }, []);

    return (
        <div>
            <Header logo={"./images/logo.png"} />
            <div className="tc_page_heading">Terms & Conditions</div>
            <section className="tc_sec">
                <div className="container">
                    <div className="tc_wapper">
                        <h3 className="heading">Terms of Service</h3>
                        <p className="para_content">
                            By using this website and/or mobile application, you acknowledge and agree to all our terms, conditions and privacy policies, and otherwise contained on or referenced in this website and mobile application (the
                            ‘agreement’). If you do not agree to this agreement, you are not authorized to access this site or use the mobile application. You also acknowledge and agree that we may modify this agreement at any time, in our
                            sole discretion; that all modifications will be effective immediately upon our posting of the modifications on this website; and that you will review this agreement each time you access this site, so that you are
                            aware of and agree to any and all modifications made to this agreement.
                        </p>
                        <p className="para_content">
                            You acknowledge and agree that use of this website and mobile application are at your own risk, and are provided on "as is" and "as available" bases; that we do not make any express or implied warranties,
                            representations or endorsements of any kind with regard to this website or mobile application (including, without limitation, warranties of title, merchantability or fitness for a particular purpose); and that we
                            do not warrant or represent that this site or any "materials" will always be accurate, reliable, useful, timely, uninterrupted, secure, defect/error-free.
                        </p>
                        <p className="para_content">
                            You acknowledge and agree that in no event will we and/or our subsidiaries, affiliates, licensors and/or suppliers be liable for any direct, indirect, incidental, consequential, special, exemplary, punitive or
                            other damages relating to your use, misuse or inability to use this website or mobile application; and that if you are dissatisfied with, or claim any damages relating to, any portion of this site, or mobile
                            application, your sole and exclusive remedy is to discontinue using this website and/or mobile application.
                        </p>
                        <p className="para_content">
                            In jurisdictions not allowing the exclusion or limitation of incidental or consequential damages, we and our subsidiaries, affiliates, licensors and suppliers liability will be limited to the extent permitted by
                            law.
                        </p>
                        <p className="para_content">
                            You acknowledge and agree that you will indemnify and hold harmless us and our subsidiaries, successors, assigns, affiliates, licensors and suppliers, together with all their respective officers, directors,
                            employees and consultants, against any and all claims, damages, losses, liabilities, judgments, costs and expenses (including reasonable attorneys' fees and costs) relating to your use, misuse or inability to use
                            this website or mobile application.
                        </p>
                        <h3 className="heading">How We Use Your Data</h3>
                        <p className="para_content">
                            We collect your personal data in order to provide and continually improve our products and services. We may collect, use, store and transfer different kinds of personal data about you for marketing and data
                            optimization purposes. OmniBiz stores:
                        </p>
                        <p className="para_content">Information you provide to us: We receive and store the information you provide to us including your identity data, contact data, delivery address, and financial data.</p>
                        <p className="para_content">
                            Information from third parties and publicly available sources: We may receive information about you from third parties including our carriers; payment service providers; merchants/brands; and advertising service
                            providers.
                        </p>
                        <p className="para_content">
                            Information on your use of our website and/or mobile applications: We automatically collect and store certain types of information regarding your use of the Jumia marketplace including information about your
                            searches, views, downloads, and purchases.
                        </p>
                        <p className="para_content">
                            “Subject to the extent permitted by the local Laws, regulations, policies, Rules and the applicable judicial, governmental, supervisory or regulatory body in the different jurisdictions, you can
                        </p>
                        <p className="para_content">delete/expunge/remove to the extent of your demand and requirement any of your personal data with us”</p>

                        <h3 className="heading">Your Cookies and How We Use Them</h3>
                        <p className="para_content">
                            Cookies allow us to distinguish you from other users of our website and mobile application. This helps us to provide you with an enhanced shopping experience. We use cookies for the following purposes:
                        </p>
                        <p className="para_content">Recognizing and counting the number of visitors and seeing how visitors move around the site when they are using it (this helps us to improve our website and other services).</p>
                        <p className="para_content">Identifying your preferences and subscriptions e.g., language settings, saved items, items stored in your cart, and.</p>
                        <p className="para_content">Sending you newsletters and commercial/advertising messages tailored to your interests.</p>
                        <h3 className="heading">Your Personal Data</h3>
                        <p className="para_content">We use your personal data to operate, provide, develop and improve the products and services that we offer, including the following:</p>
                        <p className="para_content">
                            <span>⚫</span> Registering you as a new customer.
                        </p>
                        <p className="para_content">
                            <span>⚫</span> Processing and delivering your orders.
                        </p>
                        <p className="para_content">
                            <span>⚫</span> Managing your relationship with us.
                        </p>
                        <p className="para_content">
                            <span>⚫</span> Improving our website, application, products, and services
                        </p>
                        <p className="para_content">
                            <span>⚫</span> Recommending/advertising products or services that may be of interest to you.
                        </p>
                        <p className="para_content">
                            <span>⚫</span> Enabling you to participate in promotions, competitions, and surveys.
                        </p>
                        <p className="para_content">
                            <span>⚫</span> Complying with our legal obligations, including verifying your identity where necessary.
                        </p>
                        <h3 className="heading">Data Sharing</h3>
                        <p className="para_content">We may need to share your personal data with third parties for the following purposes:</p>
                        <p className="para_content">
                            <span>⚫</span> Sale of products and services: In order to deliver your products and services purchased on our marketplace from third parties, we may be required to provide your personal data to such third
                            parties.
                        </p>
                        <p className="para_content">
                            <span>⚫</span> Working with third-party service providers: We engage third parties to perform certain functions on our behalf. Examples include fulfilling orders for products or services, delivering packages,
                            analyzing data, providing marketing assistance, processing payments, transmitting content, assessing and managing credit risk, and providing customer service.
                        </p>
                        <p className="para_content">
                            <span>⚫</span> Business transfers: As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, customer information may be transferred together with other
                            business assets.
                        </p>
                        <p className="para_content">
                            <span>⚫</span> Detecting fraud and abuse: We release accounts and other personal data to other companies and organizations for fraud protection and credit risk reduction, and to comply with the law.
                        </p>
                        <h3 className="heading">Data Security</h3>
                        <p className="para_content">
                            We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way, altered, or disclosed. In addition, we limit access to your
                            personal data to those employees, agents, contractors, and other third parties who have a business need to know. They will only process your personal data on our instructions, and they are subject to a duty of
                            confidentiality
                        </p>
                    </div>
                </div>
            </section>

            <section>
                <div className="solutions_row">
                    <div className="solutions_item4colom">
                        <OmnibizSolutions
                        image={"images/manufactur.png"}
                        backgroundColor={"rgb(231 38 53 / 75%)"}
                        solutionsfor={"OmniBiz for"}
                        title={"Manufacturers"}
                        seebtn={"/manufacturers"} />
                    </div>

                    <div className="solutions_item4colom">
                        <OmnibizSolutions
                        image={"images/distributors-bg.png"}
                        solutionsfor={"OmniBiz for"}
                        backgroundColor={"rgb(1 16 80 / 80%)"}
                        title={"Distributors"}
                        seebtn={"/distributors"} />
                    </div>

                    <div className="solutions_item4colom">
                        <OmnibizSolutions
                        image={"images/logistics-solution.png"}
                        backgroundColor={"rgb(109 29 96 / 80%)"}
                        solutionsfor={"OmniBiz for"}
                        title={"Logistics"}
                        seebtn={"/logistics"} />
                    </div>

                    <div className="solutions_item4colom">
                        <OmnibizSolutions
                        image={"images/retailers-solutions.png"}
                        backgroundColor={"rgb(18 124 66 / 80%)"}
                        solutionsfor={"OmniBiz for"}
                        title={"Retailers"}
                        seebtn={"/retailers"} />
                    </div>
                </div>
            </section>
            <Footer />
            <SignUpModal />
        </div>
    );
}

export default TermsConditions;
