import React, { useState, useEffect } from "react";
import "./SignupStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ManufacturersForm from "../solutions-modal-form/ManufacturersForm";
import DistributorsForm from "../solutions-modal-form/DistributorsForm";
import LogisticsForm from "../solutions-modal-form/LogisticsForm";

function SignUpModal({ category = "Retailer" }) {
  const [dropdownValue, setDropdownValue] = useState("");

  useEffect(() => {
    setDropdownValue(category);
  }, []);

  const handleDropdownOnChange = (event) => {
    setDropdownValue(event.target.value);
  };

  const returnLink = () => {
    if (dropdownValue === "Retailer")
      return "https://shop.omnibiz.app/registration";
    else if (dropdownValue === "Manufacturers") return "#ManufacturersForm";
    else if (dropdownValue === "Distributors") return "#DistributorsForm";
    else if (dropdownValue === "Logistics") return "#LogisticsForm";
  };

  return (
    <div>
      <div className="modal" id="signup">
        <div className="modal__content">
          <div className="modal__wrapper">
            <a href="#return" className="modal__close">
              ✕
            </a>
            <div className="signup_master">
              <h2 className="heading">Sign up on OmniBiz</h2>
              <p className="sub_heading">Create a free account</p>
              <div className="selector_card">
                <div className="selector_grp">
                  <label>I am a</label>
                  <select
                    value={dropdownValue}
                    onChange={handleDropdownOnChange}
                  >
                    <option value={"Retailer"}>Retailer</option>
                    <option value={"Manufacturers"}>Manufacturer</option>
                    <option value={"Distributors"}>Distributor</option>
                    <option value={"Logistics"}>Logistics Company</option>
                  </select>
                  <a href={returnLink()} className="proceed">
                    Proceed <FontAwesomeIcon icon={faArrowRight} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ManufacturersForm />
      <DistributorsForm />
      <LogisticsForm />
    </div>
  );
}

export default SignUpModal;
