import React, { useEffect, useState } from "react";
import "./HomeStyle.css";
import Header from "../../components/bloks/header/Header";
import Carousel from "react-multi-carousel";
import AppInstall from "../../components/app-install/AppInstall";
import Distribution from "../../components/distribution/Distribution";
import TrustedBrands from "../../components/trusted-brands/TrustedBrands";
import OmnibizSolutions from "../../components/omnibiz-solutions/OmnibizSolutions";
import JoinOver from "../../components/join-over/JoinOver";
import Footer from "../../components/bloks/footer/Footer";
import testimonialImg from "../../assets/images/tesimonials02.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import EasyandFast from "../../assets/images/easy&fast.svg";
import RangeCart from "../../assets/images/range-cart.svg";
import CreditFriendly from "../../assets/images/credit-friendly.svg";
import WorkStetfst from "../../assets/images/workstep1.svg";
import Stepcart from "../../assets/images/step-cart.svg";
import Stepcheck from "../../assets/images/step-check.svg";
import VideoModal from "../../components/video-modal/VideoModal";
import AfricaImage from "../../assets/images/africa.png.png";
import { Link } from "react-router-dom";
// import axios from "axios";

function Home() {
  const [isModel, setModel] = useState(false);
  // const [retailers, setRetailers] = useState("");
  // const [brands, setBrands] = useState("");

  // async function getRetailers() {
  //     try {
  //         const response = await axios.get("https://devomsapi.omnibiz.app/api/active-retailers");
  //         console.log(response);
  //     }
  //     catch (error) {
  //         console.log(error);
  //     }
  // };

  // async function getBrands(){
  //     try{
  //         const response = await axios.get("https://devomsapi.omnibiz.app/brands/total-active-brands");
  //         console.log(response);
  //     }
  //     catch (error) {
  //         console.log(error);
  //     }
  // };

  useEffect(() => {
    document.title = "Home";
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  // useEffect(() => {
  //     getRetailers();
  //     getBrands();
  // }, []);

  const responsive = {
    extraSuperLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 7000, min: 4000 },
      items: 1,
    },

    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <Header logo={"./images/logo.png"} />

      <Carousel
        responsive={responsive}
        autoPlay={true}
        showDots={true}
        arrows={false}
        autoPlaySpeed={8000}
        infinite={true}
        customTransition="transform 800ms ease-in-out"
      >
        <div className="banner_item banner01">
          <div className="banne-card banner-card-bg1">
            <span className="heading">
              Buy goods the easy <br /> and smart way
            </span>
            <p className="details">
              OmniBiz makes it easy for you to buy a wide <br /> range of
              consumer goods at your convenience
            </p>
            <div className="banne-card_action">
              <a href="#signup" className="signup_btn">
                Sign up Now <FontAwesomeIcon icon={faArrowRight} />
              </a>
              <a
                href="#bd-1321"
                onClick={() => setModel(true)}
                className="video_button"
              >
                <i>
                  <FontAwesomeIcon icon={faCirclePlay} />
                </i>{" "}
                Watch Video
              </a>
            </div>
          </div>
        </div>

        <div className="banner_item banner02">
          <div className="banne-card">
            <span className="heading">
              Grow your business with our <br />{" "}
              <strong>Buy More Pay Later</strong> service
            </span>
            <p className="details">
              OmniBiz makes it easy for you to buy more products on credit and
              pay later{" "}
            </p>
            <div className="banne-card_action">
              <Link to="/BMPLsignup" className="signup_btn">
                Sign up Now <FontAwesomeIcon icon={faArrowRight} />
              </Link>
              <a
                href="#bd-1322"
                onClick={() => setModel(true)}
                className="video_button"
              >
                <i>
                  <FontAwesomeIcon icon={faCirclePlay} />
                </i>{" "}
                Watch Video
              </a>
            </div>
          </div>
        </div>

        <div className="banner_item banner03">
          <div className="banne-card">
            <span className="heading">
              <strong>Get rewarded for buying from OmniBiz</strong>
            </span>
            <p className="details">
              Earn Omoni Points in your wallet everytime you buy from us
            </p>
            <div className="banne-card_action">
              <a href="#signup" className="signup_btn">
                Sign up Now <FontAwesomeIcon icon={faArrowRight} />
              </a>
              <a
                href="#bd-1323"
                onClick={() => setModel(true)}
                className="video_button"
              >
                <i>
                  <FontAwesomeIcon icon={faCirclePlay} />
                </i>{" "}
                Watch Video
              </a>
            </div>
          </div>
        </div>

        {/* <div className="banner_item banner04">
          <div className="banne-card">
            <span className="heading">
              <strong>
                Manage your store digitally <br /> from wherever you are
              </strong>
            </span>
            <p className="details">
              Retailers can utilise the OmniStore app to grow their business by
              keep track of their sales and profit!
            </p>
            <div className="banne-card_action">
              <a href="#signup" className="signup_btn">
                Sign up Now <FontAwesomeIcon icon={faArrowRight} />
              </a>
              <a
                href="#bd-1324"
                onClick={() => setModel(true)}
                className="video_button"
              >
                <i>
                  <FontAwesomeIcon icon={faCirclePlay} />
                </i>{" "}
                Watch Video
              </a>
            </div>
          </div>
        </div> */}

        <div className="banner_item banner04">
          <div className="banne-card">
            <span className="heading">
              <strong>Transact with OmniPay</strong>
            </span>
            <p className="details">
              Make use of our virtual payment solution on the OmniBiz app to pay
              for your orders, sell airtime/bills and other transactions.
            </p>
            <div className="banne-card_action">
              {/* <a href="#signup" className="signup_btn">
                OmniPay Link <FontAwesomeIcon icon={faArrowRight} />
              </a> */}
              <a
                href="#bd-1325"
                onClick={() => setModel(true)}
                className="video_button"
              >
                <i>
                  <FontAwesomeIcon
                    icon={faCirclePlay}
                    style={{ marginLeft: "-1rem" }}
                  />
                </i>{" "}
                Watch Video
              </a>
            </div>
          </div>
        </div>
      </Carousel>

      <section className="BusinessGrow_sec">
        <div className="container">
          <img className="bg_image" src={AfricaImage} alt="" />
          <div className="heading">
            <h2>
              <span>
                How OmniBiz helps your
                <br /> retail business grow
              </span>
            </h2>
          </div>

          <div className="BusinessGrow_item_holder">
            <div className="BusinessGrow_item">
              <div className="BusinessGrow_icon">
                <img src={EasyandFast} alt="" />
              </div>
              <span className="item_heading">It's Easy & Fast</span>
              <p className="details">
                OmniBiz makes it easy for retailers to restock their inventory
                in record time and at no extra costs.
              </p>
            </div>

            <div className="BusinessGrow_item">
              <div className="BusinessGrow_icon">
                <img src={RangeCart} alt="" />
              </div>
              <span className="item_heading">A wide product range</span>
              <p className="details">
                Get access to a wide range of products from your favourite
                brands in one convenient place.
              </p>
            </div>

            <div className="BusinessGrow_item">
              <div className="BusinessGrow_icon">
                <img src={CreditFriendly} alt="" />
              </div>
              <span className="item_heading">It's Pocket-friendly</span>
              <p className="details">
                Never run out stock because you are low on cash. With OmniBiz,
                you can buy now and pay later.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="working_step_sec">
        <div className="container">
          <div className="heading">
            <h2>
              <span>How OmniBiz works</span>
            </h2>
          </div>
          <div className="network_item_row">
            <div className="itwork_item">
              <div className="step_num">1</div>
              <div className="icon">
                <img img src={WorkStetfst} alt="" />
              </div>
              <span className="heading">
                Download & Sign Up on
                <br /> the OmniBiz App
              </span>
            </div>

            <div className="itwork_item">
              <div className="step_num">2</div>
              <div className="icon">
                <img img src={Stepcart} alt="" />
              </div>
              <span className="heading"> Place an order </span>
              <p />
              <p />
            </div>

            <div className="itwork_item">
              <div className="step_num">3</div>
              <div className="icon">
                <img img src={Stepcheck} alt="" />
              </div>
              <span className="heading">
                Our agent will be at
                <br /> your doorstep within
                <br /> hours
              </span>
            </div>
          </div>
        </div>
      </section>

      <AppInstall mobileview={"./images/new-app-install.png"} />
      <Distribution />
      <TrustedBrands />

      <section>
        <div className="container">
          <div className="solutions_row">
            <div className="solutions_item2colom">
              <OmnibizSolutions
                image={"images/manufactur.png"}
                backgroundColor={"rgb(231 38 53 / 75%)"}
                solutionsfor={"OmniBiz for"}
                title={"Manufacturers"}
                seebtn={"/manufacturers"}
              />
            </div>

            <div className="solutions_item2colom">
              <OmnibizSolutions
                image={"images/distributors-bg.png"}
                solutionsfor={"OmniBiz for"}
                backgroundColor={"rgb(1 16 80 / 80%)"}
                title={"Distributors"}
                seebtn={"/distributors"}
              />
            </div>

            <div className="solutions_item2colom">
              <OmnibizSolutions
                image={"images/logistics-solution.png"}
                backgroundColor={"rgb(109 29 96 / 80%)"}
                solutionsfor={"OmniBiz for"}
                title={"Logistics"}
                seebtn={"/logistics"}
              />
            </div>

            <div className="solutions_item2colom">
              <OmnibizSolutions
                image={"images/retailers-solutions.png"}
                backgroundColor={"rgb(18 124 66 / 80%)"}
                solutionsfor={"OmniBiz for"}
                title={"Retailers"}
                seebtn={"/Retailers"}
              />
            </div>
          </div>
        </div>
      </section>

      <JoinOver />

      <section>
        <div className="container">
          <div className="testimonials_card">
            <div className="testimonials_left">
              <span className="testi_stories">Happy Stories</span>
              <h2 className="testi_heading">
                Delivering happiness in every corner
              </h2>
              <p className="testi_descripe">
                Bringing you stories from some of Africa’s most enterprising
                traders, showcasing their impact in their communities and how
                OmniBiz makes this possible and easy.
              </p>
              <a
                href="#testi"
                onClick={() => setModel(true)}
                className="testi_video"
              >
                <i>
                  <FontAwesomeIcon icon={faCirclePlay} />
                </i>{" "}
                Watch Testimonials
              </a>
            </div>
            <div className="testimonials_right">
              <img src={testimonialImg} alt="" />
            </div>
          </div>
        </div>
      </section>

      <Footer />

      {isModel && (
        <VideoModal
          closeModel={setModel}
          modalid={"bd-1321"}
          videolink={"https://www.youtube.com/embed/ZyST0HRk0-Q"}
        />
      )}

      {isModel && (
        <VideoModal
          closeModel={setModel}
          modalid={"bd-1322"}
          videolink={"https://www.youtube.com//embed/J4C4T2EZ8oM"}
        />
      )}

      {isModel && (
        <VideoModal
          closeModel={setModel}
          modalid={"bd-1323"}
          videolink={"https://www.youtube.com/embed/-JoG5FtJoV8"}
        />
      )}

      {isModel && (
        <VideoModal
          closeModel={setModel}
          modalid={"bd-1324"}
          videolink={"https://www.youtube.com/embed/m6A-dS-cqQU"}
        />
      )}

      {isModel && (
        <VideoModal
          closeModel={setModel}
          modalid={"bd-1325"}
          videolink={"https://www.youtube.com/embed/XAeuR36SodY"}
        />
      )}

      {/* ---------------Testimonials Slider------------- */}
      {isModel && (
        <VideoModal
          closeModel={setModel}
          modalid={"testi"}
          videolink={"https://www.youtube.com/embed/bGPwFOrfV04"}
        />
      )}
    </div>
  );
}

export default Home;
