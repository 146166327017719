import './AppInstall-Style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'

function AppInstall({mobileview}) {
    
    return (
        <>
            <div className='desktop'>
                <main>
                    <div className="container">
                        <div className="app_desaer">
                            <div className='left_app_desaer'>
                                <h3>Join over <span className='heightlighted_txt'>120,000</span> retailers doing business the smarter and easier way.</h3>
                                <a href="https://play.google.com/store/apps/details?id=com.omnibiz.retail&hl=en_IN&gl=US" target="_blank" rel="noreferrer" className='download_app'>Download the app <FontAwesomeIcon icon={faArrowRight} /></a>
                                </div>
                                <div className='right_app_desaer' >
                                    <img className='app_view' src={mobileview} alt=''/>
                                    {/* <img className='app_view_bg' src='./images/Subtract.png' alt=''/> */}
                                </div>
                            </div>
                        </div>
                </main>
            </div>
            <div className='mobile'>
                <main>
                    <div className="container">
                        <div className="app_desaer">
                            <div className='left_app_desaer'>
                                <h3>Join over <span className='heightlighted_txt'>120,000</span> retailers doing business the smarter and easier way.</h3>
                                <a href="https://app.omnibiz.com/refer" target="_blank" rel="noreferrer" className='download_app'>Download the app <FontAwesomeIcon icon={faArrowRight} /></a>
                                </div>
                                <div className='right_app_desaer' >
                                    <img className='app_view' src={mobileview} alt=''/>
                                    {/* <img className='app_view_bg' src='./images/Subtract.png' alt=''/> */}
                                </div>
                            </div>
                        </div>
                </main>
            </div>
        </>
    )
}

export default AppInstall