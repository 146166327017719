import React, { useEffect } from "react";
// import logo from './logo.svg';
import "./App.css";
import Home from "../src/pages/home/Home";
import About from "../src/pages/about/About";
import Retailers from "../src/pages/retailers/Retailers";
import Manufacturers from "../src/pages/manufacturers/Manufacturers";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Distributors from "../src/pages/distributors/Distributors";
import Logistics from "../src/pages/logistics/Logistics";
import ContactUs from "../src/pages/conactus/ContactUs";
import Faq from "../src/pages/faq/Faq";
import TermsConditions from "../src/pages/terms&conditions/TermsConditions";
import BMPLsignup from "./pages/BMPLsignup";
import LocateStore from "./pages/locateStore";

function App() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/retailers" element={<Retailers />} />
        <Route exact path="/manufacturers" element={<Manufacturers />} />
        <Route exact path="/distributors" element={<Distributors />} />
        <Route exact path="/logistics" element={<Logistics />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/terms&conditions" element={<TermsConditions />} />
        <Route exact path="/BMPLsignup" element={<BMPLsignup />} />
        <Route exact path="/locate-retailers" element={<LocateStore />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
