import './Distribution-Style.css'

function Distribution() {
    
    return (
        <div>
           <div className="container">
               <div className="distribution_holder">
                   <h3 className="heading">We’ve got retail <br/> distribution covered</h3>
                   <p className='details'>We aggregate end-to-end retail operations to help manufacturers, distributors, logistic partners, and retailers achieve their business goals and through a seamless supply chain framework.</p>
                   </div>
               </div>
        </div>
    )
}

export default Distribution