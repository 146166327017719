import React from "react";
import "./BannerCard-Style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import SignUpModal from "../../components/signup-modal/SignUpModal";

function Retailers({ icon, forOmni, heading, describe, button, backgroundColor, videoPlay }) {
    return (
        <div>
            <div className="outer_banner-card" backgroundColor={backgroundColor} style={{ backgroundImage: backgroundColor }}>
                <img itemProp="icon" className="card_icon" src={icon}  alt="" />
                <span itemProp="forOmni" className="card_for">
                    {forOmni}
                </span>
                <span itemProp="heading" className="heading">
                    {heading}
                </span>
                <p itemProp="describe" className="details">
                    {describe}
                </p>
                <div className="outer_banner-card_action">
                    <a href="#signup" itemProp="button" class="signup_btn">
                        {button}{" "}
                        <i>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </i>
                    </a>
                </div>
            </div>

            <SignUpModal category={heading} />
        </div>
    );
}

export default Retailers;
