import "./loader.css";

const Loader = (props) => {
  const { isSectionLoader = false } = props;
  if (isSectionLoader) {
    return <SectionLoader {...props} />;
  } else {
    return (
      <>
        <div class="loading">Loading&#8230;</div>
      </>
    );
  }
};

const SectionLoader = (props) => {
  const { show } = props;
  if (show) {
    return <div className="section-loader"></div>;
  } else return null;
};

export default Loader;
