import React from "react";
import "./SteperStyle.css";

function Steper({ title, heading, description }) {
    return (
        <div>
            <div className="steper_content">
                <span itemProp="title" className="title">
                    {title}
                </span>
                <h2 itemProp="heading" className="heading">
                    {heading}
                </h2>
                <p itemProp="description" className="description">
                    {description}
                </p>
            </div>
        </div>
    );
}

export default Steper;
