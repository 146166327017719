import "./TrustedBrandsStyle.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import brand01 from "../../assets/images/brand01.png";
import colgate from "../../assets/images/colgate.png";
import indomie from "../../assets/images/indomie.png";
import kellogg from "../../assets/images/kellogg.png";
import kimberly from "../../assets/images/kimberly.png";
import nestie from "../../assets/images/nestie.png";
import sevenUp from "../../assets/images/7up.png"
import arla from "../../assets/images/arla.png"
import checkers from "../../assets/images/checkers.png"
import dufil from "../../assets/images/dufil.png"
import fmn from "../../assets/images/fmn.png"
import hypo from "../../assets/images/hypo.png"
import pzWilmar from "../../assets/images/pz-wilmar.png"
import sonia from "../../assets/images/sonia.png"

function TrustedBrands() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 9,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    },
  };

  return (
    <div>
      <main>
        <div className="container">
          <div className="trustedbrands_holder">
            <div className="heading">
              <span>We Are Trusted By</span>
            </div>
            <Carousel
              responsive={responsive}
              autoPlay={true}
              arrows={false}
              autoPlaySpeed={1500}
              infinite={true}
            >
              <div>
                <img src={brand01} alt="" />
              </div>
              <div>
                <img src={colgate} alt="" />
              </div>
              <div>
                <img src={indomie} alt="" />
              </div>
              <div>
                <img src={kellogg} alt="" />
              </div>
              <div>
                <img src={kimberly} alt="" />
              </div>
              <div>
                <img src={nestie} alt="" />
              </div>
              <div>
                <img src={sevenUp} alt="" />
              </div>
              <div>
                <img src={arla} alt="" />
              </div>
              <div>
                <img src={checkers} alt="" />
              </div>
              <div>
                <img src={dufil} alt="" />
              </div>
              <div>
                <img src={fmn} alt="" />
              </div>
              <div>
                <img src={hypo} alt="" />
              </div>
              <div>
                <img src={pzWilmar} alt="" />
              </div>
              <div>
                <img src={sonia} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
      </main>
    </div>
  );
}

export default TrustedBrands;
