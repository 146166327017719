import "./OmnibizSolutions-Style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function omnibizSolutions({ image, solutionsfor, backgroundColor, title, seebtn }) {
    return (
        <div>
            <main>
                <Link to={seebtn} itemProp="seebtn">
                    <div className="solutions_item">
                        <img itemProp="image" className="solutions_img" src={image}  alt="" />
                        <div itemProp="backgroundcolor" className="solutions_description" backgroundColor={backgroundColor} style={{ background: backgroundColor }}>
                            <div itemProp="solutionsfor" className="solutions_for">
                                {solutionsfor}
                            </div>
                            <h3 itemProp="title" className="solution_title">
                                {title}
                            </h3>
                            <Link to={seebtn}>
                                <span itemProp="seebtn" className="solution_link">
                                    See Details <FontAwesomeIcon icon={faArrowRight} />
                                </span>
                            </Link>
                        </div>
                    </div>
                </Link>
            </main>
        </div>
    );
}

export default omnibizSolutions;
