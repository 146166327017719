import React, { useEffect } from "react";
import "./AboutPage-Style.css";
import Header from "../../components/bloks/header/Header";
import Footer from "../../components/bloks/footer/Footer";
import Prider from "../../assets/images/pider.svg";
import Home from "../../assets/images/home.svg";
import Truck from "../../assets/images/truck.svg";
import Finance from "../../assets/images/finance.svg";
import Analytics from "../../assets/images/analytics.svg";
import Brands from "../../assets/images/platform-brands.png";
import Retailers from "../../assets/images/platform-retailers.png";
import Mvsion from "../../assets/images/mvsion.png";
import Map from "../../assets/images/traction-map.png";
import TeamProfile from "../../components/team-profile/TeamProfile";
import OmnibizSolutions from "../../components/omnibiz-solutions/OmnibizSolutions";
import Distribution from "../../components/distribution/Distribution";
import DummyProfile from "../../assets/images/dummy-user.png";
import SignupModal from "../../components/signup-modal/SignUpModal";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

function About() {
  useEffect(() => {
    document.title = "About Us";
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  }, []);

  return (
    <div>
      <Header logo={"./images/logo.png"} />

      <main className="grey_bg">
        <section className="about_banner"></section>

        <section>
          <div className="container">
            <div className="abut_beginning">
              <div className="col_left">
                <span className="we_R">We Are</span>
                <h1 className="heading">OmniBiz</h1>
              </div>

              <div className="col_right">
                <p className="abut_beginning_p">
                  OmniBiz provides a unified B2B distribution platform powered
                  by technology.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="growth_Engine">
          <div className="container">
            <div className="abt_heading_warp">
              <h2>Our solution is a growth engine</h2>
              <p>
                By bringing key stakeholder on a single platform, OmniBiz
                eliminates stock out and provides access to working capital.
              </p>
            </div>

            <div className="platform_row">
              <div className="col_left">
                <div className="plat_option">
                  <img
                    className="BBB"
                    src={Brands}
                    alt=""
                    style={{ position: "relative", top: "20px", left: "15px" }}
                  />
                  <span
                    className="title AAA"
                    style={{
                      position: "relative",
                      top: window.screen.width < 767 ? "-5px" : "20px",
                      left: "15px",
                    }}
                  >
                    Brands
                  </span>
                </div>
              </div>

              <div className="omnibiz_platform">
                <div className="title">
                  <img className="icon" src={Prider} alt="" />
                  <h4>The OmniBiz Platform</h4>
                </div>

                <div className="platform_toppy">
                  <div className="rotation_content">
                    Crowdsourced <br /> Fulfilment Platform
                  </div>
                  <div className="item">
                    <div className="item_icon">
                      <img src={Home} alt="" />
                    </div>
                    <div className="describe">
                      <h5>Micro Stock Point engine</h5>
                      <p>Large network of distributor spaces and inventory</p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="item_icon">
                      <img src={Truck} alt="" />
                    </div>
                    <div className="describe">
                      <h5>Crowdsourced Logistics</h5>
                      <p>
                        Utilize the capacity of regional logistics companies on
                        demand
                      </p>
                    </div>
                  </div>
                </div>

                <div className="platform_toppy">
                  <div className="item">
                    <div className="item_icon">
                      <img src={Finance} alt="" />
                    </div>
                    <div className="describe">
                      <h5>Financial Services Partners</h5>
                      <p>
                        Provide financial services to retailers and their
                        customers
                      </p>
                    </div>
                  </div>
                </div>

                <div className="platform_toppy">
                  <div className="item">
                    <div className="item_icon">
                      <img src={Analytics} alt="" />
                    </div>
                    <div className="describe">
                      <h5>Analytics Engine</h5>
                      <p>
                        Building insights from the data for all stakeholders{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col_right">
                <div className="plat_option mt-5-mb">
                  <img className="BBB" src={Retailers} alt="" />
                  <span className="title AAA">Retailers</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="abt_white">
          <div className="container">
            <div className="mvsion_row">
              <div className="mvsion_grid">
                <div className="mvsion_warp">
                  <h3 className="heading">Our Vision</h3>
                  <p className="descibe">
                    To be the preferred growth partner of the retail ecosystem
                    in developing markets.
                  </p>
                  <hr color="#D0D0D0" style={{ margin: "30px 0" }} />
                  <h3 className="heading">Our Mission</h3>
                  <p className="descibe">
                    Maximizing the ROI of every retailer through a user-friendly
                    platform.
                  </p>
                </div>
              </div>
              <div className="mvsion_grid">
                <img className="mvsion_img" src={Mvsion} alt="" />
              </div>
            </div>
          </div>
        </section> */}

        <section className="sec_space team_sec">
          <div className="container">
            <div className="abt_heading_warp">
              <h2 style={{ marginBottom: "20px" }}>OmniBiz Team</h2>
              <Link to="/contact-us" className="joinTeam">
                <FontAwesomeIcon icon={faUserPlus} /> Join Our Team
              </Link>
            </div>

            <div className="team_card">
              <div className="team_row">
                <TeamProfile
                  image={"images/deepankar.png"}
                  name={"Deepankar Rustagi"}
                  designation={"Founder/CEO"}
                  linkedin={"https://www.linkedin.com/in/deepankarrustagi/"}
                />

                <TeamProfile
                  image={"images/Adewale-Adisa.jpg"}
                  name={"Adewale Adisa"}
                  designation={"COO (West Africa)"}
                  linkedin={"https://www.linkedin.com/in/waleadisa/"}
                />

                <TeamProfile
                  image={"images/dika.png"}
                  name={"Dika Oha"}
                  designation={"Chief Innovation Officer"}
                  linkedin={"https://www.linkedin.com/in/dikao/"}
                />

                <TeamProfile
                  image={"images/akinwunmi.png"}
                  name={"Akinwunmi Ogunnusi"}
                  designation={"Head of Business Processes"}
                  linkedin={
                    "https://www.linkedin.com/in/akinwunmi-ogunnusi-28471226/"
                  }
                />

                <TeamProfile
                  image={"images/Amber.png"}
                  name={"Amber Yadav"}
                  designation={"Head of Demand"}
                  linkedin={"https://www.linkedin.com/in/amberkumaryadav/"}
                />

                <TeamProfile
                  image={"images/Archit.jpg"}
                  name={"Archit Bagaria"}
                  designation={"Head of Investments"}
                  linkedin={
                    "https://www.linkedin.com/in/archit-bagaria-30a09179/"
                  }
                />

                <TeamProfile
                  image={"images/Pankag's Headshot.png"}
                  name={"Pankaj Badola"}
                  designation={"Head of Manufacturer Relations"}
                  linkedin={"https://www.linkedin.com/in/pankajbadola/"}
                />

                <TeamProfile
                  image={"images/Priya.png"}
                  name={"Priya Armanani"}
                  designation={"Head of Product Operations"}
                  linkedin={"https://www.linkedin.com/in/priyamelwani/"}
                />

                <TeamProfile
                  image={"images/vachan.png"}
                  name={"Vachan Lohia"}
                  designation={"Head of Finance"}
                  linkedin={
                    "https://www.linkedin.com/in/vachan-lohia-b1b52556/"
                  }
                />

                <TeamProfile
                  image={"images/zainab.png"}
                  name={"Zainab Arilesere"}
                  designation={" Head of Product"}
                  linkedin={"https://www.linkedin.com/in/zainab-arilesere/"}
                />

                <div>
                  <Link to="/contact-us" className="team">
                    <div className="img_link">
                      <img src={DummyProfile} alt="" />
                    </div>
                    <span itemProp="name" className="name">
                      This could be you
                    </span>
                    <span itemProp="designation" className="designation">
                      Join us: hrbp@omnibiz.com
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="traction_sec">
          <div className="traction_plate">
            <span className="tag">TRACTION</span>
          </div>
          <div className="traction_plate_mb">
            <span className="tag">TRACTION</span>
          </div>
          <div className="container">
            <div className="traction_row">
              <div className="col_left">
                <div className="traction_grid">
                  <span className="count">120,000+</span>
                  <p className="describe">
                    Retailers doing business the smarter way
                  </p>
                </div>
                {/* <div className='traction_grid'>
                <span className='count'>70,000+</span>
                <p className='describe'>Businesses trust and do business with us</p>
              </div> */}
                <div className="traction_grid">
                  <span className="count">1,500+</span>
                  <p className="describe">Field Agents across Nigeria</p>
                </div>
                <div className="traction_grid">
                  <span className="count">135+</span>
                  <p className="describe">
                    Local governments covered across Nigeria and Ghana
                  </p>
                </div>
              </div>

              <div className="col_right">
                <img className="map" src={Map} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="abt_white p-0">
          <Distribution />
        </section>

        <section>
          <div className="solutions_row">
            <div className="solutions_item4colom">
              <OmnibizSolutions
                image={"images/manufactur.png"}
                backgroundColor={"rgb(231 38 53 / 75%)"}
                solutionsfor={"OmniBiz for"}
                title={"Manufacturers"}
                seebtn={"/manufacturers"}
              />
            </div>

            <div className="solutions_item4colom">
              <OmnibizSolutions
                image={"images/distributors-bg.png"}
                solutionsfor={"OmniBiz for"}
                backgroundColor={"rgb(1 16 80 / 80%)"}
                title={"Distributors"}
                seebtn={"/distributors"}
              />
            </div>

            <div className="solutions_item4colom">
              <OmnibizSolutions
                image={"images/logistics-solution.png"}
                backgroundColor={"rgb(109 29 96 / 80%)"}
                solutionsfor={"OmniBiz for"}
                title={"Logistics"}
                seebtn={"/logistics"}
              />
            </div>

            <div className="solutions_item4colom">
              <OmnibizSolutions
                image={"images/retailers-solutions.png"}
                backgroundColor={"rgb(18 124 66 / 80%)"}
                solutionsfor={"OmniBiz for"}
                title={"Retailers"}
                seebtn={"/retailers"}
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <SignupModal />
    </div>
  );
}

export default About;
