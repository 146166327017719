import "./JoinOver-Style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import SignUpModal from "../../components/signup-modal/SignUpModal";
import { Link } from "react-router-dom";

function JoinOver() {
    return (
        <div>
            <main className="joinOver_holder">
                <div className="conteiner">
                    <div className="joinOver_card">
                        <h3 className="joinOver_h3js">Join Over</h3>
                        <span className="joinOver_hightlight">300 Brands</span>
                        <h3 className="joinOver_h3js">That trust and work with us.</h3>
                        <div className="joinOver_actions">
                            <a href="#signup" className="signup">
                                Sign up now <FontAwesomeIcon icon={faArrowRight} />
                            </a>
                            <Link to="/contact-us" className='contact'>Contact us <FontAwesomeIcon icon={faArrowRight} /></Link>
                        </div>
                    </div>
                </div>
            </main>
            <SignUpModal />
        </div>
    );
}

export default JoinOver;
