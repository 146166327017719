import React from "react";
import "./SteperStyle.css";

function Steper({ image }) {
    return (
        <div>
            <div className="steper_img">
                <img className="steper_img" itemProp="image" src={image}  alt="" />
            </div>
        </div>
    );
}

export default Steper;
