import React, { useEffect } from "react";
import "./ContactUs.css";
import Header from "../../components/bloks/header/Header";
import Footer from "../../components/bloks/footer/Footer";
import OmnibizSolutions from "../../components/omnibiz-solutions/OmnibizSolutions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import SignupModal from "../../components/signup-modal/SignUpModal";

function ContactUs() {
  useEffect(() => {
    document.title = "Contact Us";
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  }, []);

  return (
    <div>
      <Header logo={"./images/logo.png"} />

      <section className="contactus_sec">
        <div className="container">
          <div className="contact_wrapper">
            <div className="col_left">
              <div className="heading">
                <h1>Get in touch with us</h1>
                <p>Do you want to sign up or ask a question or make enquiry? Please drop a message for us and we will be in touch with you.</p>
              </div>

              <form className="contact_form" method="post" action="https://submit-form.com/qpqbe28Y">
                <div className="form_droup">
                  <input type="text" placeholder="Full name" name="fullName" />
                </div>
                <div className="form_droup">
                  <input className="left_input" type="text" placeholder="Email Address" name="emailAddress" />
                  <input className="right_input" type="text" placeholder="Phone number" name="phoneNumber" />
                </div>
                <div className="form_droup">
                  <input type="text" placeholder="Company Name" name="companyName" />
                </div>
                <div className="form_droup">
                  <textarea type="text" placeholder="Message" name="message"></textarea>
                </div>
                <div className="form_droup">
                  <button type="submit">
                    Send Message <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>
              </form>
            </div>

            <div className="col_right">
              <div className="contect_slot">
                <h4>Office Address</h4>
                <p>6, Causeway road, Ijora, Lagos</p>
              </div>
              <div className="contect_slot">
                <h4>Email Address</h4>
                <p>
                  <b>Customer Enquiries</b>
                  <br />
                  enquiries@omnibiz.com
                </p>
                <p>
                  <b>Potential Partnership</b>
                  <br />
                  partnership@omnibiz.com
                </p>
              </div>
              <div className="contect_slot">
                <h4>Phone/Whatsapp</h4>
                <p>+234 700 0055 555</p>
                <p>+234 800 0900 999</p>
              </div>

              <div className="contect_slot">
                <h4>We’re social</h4>
                <div className="social_links">
                  <a href="https://www.facebook.com/omnibizafrica" target="_blank" rel="noreferrer">
                    <FaFacebook />{" "}
                  </a>
                  <a href="https://twitter.com/omnibizafrica" target="_blank" rel="noreferrer">
                    <FaTwitter />{" "}
                  </a>
                  <a href="https://www.instagram.com/omnibizafrica/" target="_blank" rel="noreferrer">
                    <FaInstagram />{" "}
                  </a>
                  <a href="https://www.linkedin.com/company/omnibiz-africa" target="_blank" rel="noreferrer">
                    <FaLinkedin />{" "}
                  </a>
                  <a href="https://www.youtube.com/channel/UCbQgTtFNs-DCD-rJxoPMJkg/videos" target="_blank" rel="noreferrer">
                    <FaYoutube />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="solutions_row">
          <div className="solutions_item4colom">
            <OmnibizSolutions image={"images/manufactur.png"} backgroundColor={"rgb(231 38 53 / 75%)"} solutionsfor={"OmniBiz for"} title={"Manufacturers"} seebtn={"/manufacturers"} />
          </div>

          <div className="solutions_item4colom">
            <OmnibizSolutions image={"images/distributors-bg.png"} solutionsfor={"OmniBiz for"} backgroundColor={"rgb(1 16 80 / 80%)"} title={"Distributors"} seebtn={"/distributors"} />
          </div>

          <div className="solutions_item4colom">
            <OmnibizSolutions image={"images/logistics-solution.png"} backgroundColor={"rgb(109 29 96 / 80%)"} solutionsfor={"OmniBiz for"} title={"Logistics"} seebtn={"/logistics"} />
          </div>

          <div className="solutions_item4colom">
            <OmnibizSolutions image={"images/retailers-solutions.png"} backgroundColor={"rgb(18 124 66 / 80%)"} solutionsfor={"OmniBiz for"} title={"Retailers"} seebtn={"/retailers"} />
          </div>
        </div>
      </section>
      <Footer />
      <SignupModal />
    </div>
  );
}

export default ContactUs;
