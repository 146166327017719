import React, { useEffect } from "react";
import "./FaqStyle.css";
import Header from "../../components/bloks/header/Header";
import Footer from "../../components/bloks/footer/Footer";
import Phone1 from "../../assets/images/faqPhone1.png";
import Phone2 from "../../assets/images/faqPhone2.png";
import Phone3 from "../../assets/images/faqPhone3.png";
import Phone4 from "../../assets/images/faqPhone4.png";
// import Phone5 from "../../assets/images/faqPhone5.png";
import Phone6 from "../../assets/images/faqPhone6.png";
import TrackOrder from "../../assets/images/trackOrder.png";
import Omnipoints from "../../assets/images/omni-opints.png";
import Mycart from "../../assets/images/my-cart.png";
import useOmoniPoints from "../../assets/images/useOmoniPoints.png";
import paymentOmoniPoints from "../../assets/images/paymentOmoniPoints.png";
// import OmniPayWallet from "../../assets/images/omnipay-wallet.png";
// import OmniPayAddMoney from "../../assets/images/omnipay-addmoney.png";
// import OmniPayBanks from "../../assets/images/omnipay-banks.png";
import OmniPayQr from "../../assets/images/omnipay-qr.png";
// import Airtime1 from "../../assets/images/airtime1.png";
import Airtime2 from "../../assets/images/airtime2.png";
// import Airtime3 from "../../assets/images/airtime3.png";
import Airtime4 from "../../assets/images/airtime4.png";
import OmniPay_Payment1 from "../../assets/images/omnipay-payment1.png";
// import OmniPay_Payment2 from "../../assets/images/omnipay-payment2.png";
import OmniPay_Payment3 from "../../assets/images/omnipay-payment3.png";
// import OmniPay_Payment4 from "../../assets/images/omnipay-payment4.png";
import OmniPay_Payment5 from "../../assets/images/omnipay-payment5.png";
import AddMoney1 from "../../assets/images/add-money1.png";
import AddMoney2 from "../../assets/images/add-money2.png";
// import Checkout from "../../assets/images/checkout.png";
// import Payment from "../../assets/images/payment.png";
import SignUpModal from "../../components/signup-modal/SignUpModal";

function Faq() {
  useEffect(() => {
    document.title = "FAQs";
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  }, []);

  return (
    <div>
      <Header logo={"./images/logo.png"} />

      <section className="sec_space">
        <div className="container">
          <div className="faq_heading">
            <h1>OmniBiz Retail FAQs</h1>
          </div>
          <section data-accordion>
            <div data-accordion-item>
              <input type="checkbox" id="panel-1" />
              <label for="panel-1">Why OmniBiz?</label>
              <div data-accordion-panel>
                OmniBiz is a business platform that brings together distributors
                and retailers of Fast-Moving Consumer Goods (FMCG). We connect
                distributors to thousands of retailers and bring products right
                to the doorsteps of our retailers. When you become a retailer on
                OmniBiz, you enjoy stress- free, zero- cost delivery service.
              </div>
            </div>
            <div data-accordion-item>
              <input type="checkbox" id="panel-2" />
              <label for="panel-2" data-accordion-label>
                How do I register on OmniBiz?{" "}
              </label>
              <div data-accordion-panel>
                To register as an agent or retailer on OmniBiz, kindly{" "}
                <a
                  href="https://shop.omnibiz.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here
                </a>
              </div>
            </div>

            <div data-accordion-item>
              <input type="checkbox" id="panel-3" />
              <label for="panel-3" data-accordion-label>
                What can I buy on OmniBiz?
              </label>
              <div data-accordion-panel>
                You can buy a wide range of goods on the OmniBiz app covering
                different categories from groceries to toiletries and
                provisions.
              </div>
            </div>

            <div data-accordion-item>
              <input type="checkbox" id="panel-4" />
              <label for="panel-4" data-accordion-label>
                How Can I install the OmniBiz app?{" "}
              </label>
              <div data-accordion-panel>
                To download the OmniBiz app, kindly follow the instructions
                below
                <p>
                  <span className="faq_number">01</span> Open Google Chrome
                  browser and type in{" "}
                  <a
                    href="https://shop.omnibiz.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    shop.omnibiz.com
                  </a>
                </p>
                <img className="faq_phons" src={Phone1} alt="" />
                <p>
                  <span className="faq_number">02</span> Type in your phone
                  number and click on the proceed button. Type in the 6-digits
                  code sent to your phone number and click{" "}
                  <strong>Verify</strong>.
                </p>
                <img className="faq_phons" src={Phone2} alt="" />
                <p>
                  <span className="faq_number">03</span> Once you are
                  successfully registered, proceed to install the app by
                  clicking on the <strong>My account</strong> menu at the bottom
                  right corner.
                </p>
                <img className="faq_phons" src={Phone3} alt="" />
                <p>
                  <span className="faq_number">04</span> Click on the{" "}
                  <strong>'Download the app now'</strong> button and do the same
                  on the pop- up.
                </p>
                <img className="faq_phons" src={Phone4} alt="" />
              </div>
            </div>

            <div data-accordion-item>
              <input type="checkbox" id="panel-5" />
              <label for="panel-5" data-accordion-label>
                How do I place orders on the OmniBiz app?
              </label>
              <div data-accordion-panel>
                You can place orders on OmniBiz by using this link{" "}
                <a
                  href="https://shop.omnibiz.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  shop.omnibiz.com
                </a>{" "}
                . To order through the OmniBiz app, follow the steps below;
                <p>
                  <span className="faq_number">01</span> Open the OmniBiz App
                </p>
                <p>
                  <span className="faq_number">02</span> Click on the Add to
                  Cart button.
                </p>
                <img className="faq_phons" src={Phone3} alt="" />
                <p>
                  <span className="faq_number">03</span> Click on the checkout
                  button to pay. You can type in a coupon code and click on
                  confirm order to proceed.
                </p>
                <img className="faq_phons" src={Phone6} alt="" />
              </div>
            </div>

            <div data-accordion-item>
              <input type="checkbox" id="panel-6" />
              <label for="panel-6" data-accordion-label>
                How do I check prices on OmniBiz?
              </label>
              <div data-accordion-panel>
                To check prices and view all available products,{" "}
                <a href="https://shop.omnibiz.com">click here.</a>
              </div>
            </div>

            <div data-accordion-item>
              <input type="checkbox" id="panel-7" />
              <label for="panel-7" data-accordion-label>
                How is OmniBiz delivery and payment mode?{" "}
              </label>
              <div data-accordion-panel>
                It takes 2 business days (48 hours) for your orders to be
                delivered to your address. Payment is done virtually (through
                the retailer’s virtual account) or manually (Paying Cash to the
                driver) after orders have been delivered
              </div>
            </div>

            <div data-accordion-item>
              <input type="checkbox" id="panel-8" />
              <label for="panel-8" data-accordion-label>
                How can I track my orders on OmniBiz?
              </label>
              <div data-accordion-panel>
                <p>
                  To track your orders on the OmniBiz app, use the click-flow
                  below;
                </p>
                <img className="faq_phons" src={TrackOrder} alt="" />
              </div>
            </div>

            <div data-accordion-item>
              <input type="checkbox" id="panel-9" />
              <label for="panel-9" data-accordion-label>
                How is OmniBiz delivery and payment mode?
              </label>
              <div data-accordion-panel>
                <p>
                  It takes 2 business days (48 hours) for your orders to be
                  delivered to your address. Payment is done virtually (through
                  the retailer’s virtual account) or manually (paying cash to
                  the driver) at the point of delivery.
                </p>
              </div>
            </div>

            <div data-accordion-item>
              <input type="checkbox" id="panel-10" />
              <label for="panel-10" data-accordion-label>
                Do I need collateral to use OmniBiz BNPL feature?
              </label>
              <div data-accordion-panel>
                <p>
                  With our Buy more, pay later, our customers have access to
                  credit funds with no collateral needed and no hidden charges.
                </p>
              </div>
            </div>

            <div data-accordion-item>
              <input type="checkbox" id="panel-11" />
              <label for="panel-11" data-accordion-label>
                What are Omoni Points?
              </label>
              <div data-accordion-panel>
                <p>
                  Omoni Points is a loyalty reward given to customers on the
                  OmniBiz app.
                </p>
                <p>1 Omoni Point = 1 Naira</p>
                <img className="faq_phons" src={Omnipoints} alt="" />
              </div>
            </div>

            <div data-accordion-item>
              <input type="checkbox" id="panel-12" />
              <label for="panel-12" data-accordion-label>
                How do I use my Omoni Points?
              </label>
              <div data-accordion-panel>
                <p>
                  To use your Omoni points on the OmniBiz app, follow the steps
                  below:
                </p>
                <p>
                  <span className="faq_number">01</span> Open the OmniBiz app
                  and add the products you would like to buy to your cart.
                </p>
                <p>
                  <span className="faq_number">02</span> At the point of check
                  out, you will see the amount of Omoni points in your wallet.
                </p>
                <img className="faq_phons" src={Mycart} alt="" />
                <p>
                  <span className="faq_number">03</span> Click on “Use” and your
                  Omoni points will be immediately deducted from your Total
                  Amount Due.{" "}
                </p>
                <img className="faq_phons" src={useOmoniPoints} alt="" />
                <p>
                  <span className="faq_number">04</span> Click on the <strong>Continue
                  to payment</strong> button.
                </p>
                <p>
                  <span className="faq_number">05</span> Select your preferred
                  payment option and proceed to pay the discounted amount.
                </p>
                <img className="faq_phons" src={paymentOmoniPoints} alt="" />
              </div>
            </div>

            <div data-accordion-item>
              <input type="checkbox" id="panel-13" />
              <label for="panel-13" data-accordion-label>
                What is OmniPay?
              </label>
              <div data-accordion-panel>
                <p>
                  OmniPay is an integrated virtual payment solution embedded in
                  the OmniBiz app to simplify daily transactions for retailers.
                  With OmniPay, retailers can now make payments for their orders
                  via the OmniBiz app and pay for different kinds of bills
                  (Airtime/date, TV subscriptions, electronic bills, etc.)
                </p>
              </div>
            </div>

            <div data-accordion-item>
              <input type="checkbox" id="panel-14" />
              <label for="panel-14" data-accordion-label>
                How to Add Money to your OmniPay wallet?
              </label>
              <div data-accordion-panel>
                <p>
                  <span className="faq_number">01</span> Open the OmniBiz app,
                  click on the <strong>Wallet</strong> icon and select{" "}
                  <strong>Add Money</strong>.
                </p>
                <img className="faq_phons" src={AddMoney1} alt="" />
                <p>
                  <span className="faq_number">02</span> Select any bank of your
                  choice and copy the account numbers (All bank accounts are
                  linked to your OmniPay wallet).
                </p>
                <img className="faq_phons" src={AddMoney2} alt="" />
                <p>
                  <span className="faq_number">03</span> Go to your local bank
                  app and transfer to the copied account numbers.
                </p>
                <p>
                  <span className="faq_number">04</span> Your customers can pay
                  into your wallet by scanning your printed QR code in your
                  store or in your app.
                </p>
                <img className="faq_phons" src={OmniPayQr} alt="" />
                <p>
                  <span className="faq_number">05</span> Your customers can
                  select their preferred bank from your list of banks and copy
                  the account numbers.
                </p>
                <p>
                  <span className="faq_number">06</span> Your customers can
                  transfer from their local bank accounts to your copied account
                  bank numbers.
                </p>
              </div>
            </div>

            {/* <div data-accordion-item>
              <input type="checkbox" id="panel-15" />
              <label for="panel-15" data-accordion-label>
                How do I pay for my orders on the OmniPay wallet?
              </label>
              <div data-accordion-panel>
                <img className="faq_phons" src={OmniPayWallet} alt="" />
                <p>
                  <span className="faq_number">01</span> Open the OmniBiz app,
                  click on the <strong>My Wallet </strong>
                  button and click on <strong>Add money</strong>
                </p>
                <img className="faq_phons" src={OmniPayAddMoney} alt="" />
                <p>
                  <span className="faq_number">02</span> Select any bank account
                  details of your choice. (All account details all links to you
                  OmniPay wallet)
                </p>
                <img className="faq_phons" src={OmniPayBanks} alt="" />
                <p>
                  <span className="faq_number">03</span> Go to your local bank
                  app and transfer to the copied account detail.
                </p>
                <p>
                  <span className="faq_number">04</span> Customers can pay into
                  the wallet via scanning the retailer's QR code.
                </p>
                <img className="faq_phons" src={OmniPayQr} alt="" />
              </div>
            </div> */}

            <div data-accordion-item>
              <input type="checkbox" id="panel-16" />
              <label for="panel-16" data-accordion-label>
                How do I sell Airtime/Data with OmniPay?
              </label>
              <div data-accordion-panel>
                <p>
                  <span className="faq_number">01</span> Open the OmniBiz app,
                  click on the <strong>My wallet </strong>
                  button and click on <strong>Airtime/Data</strong>.
                </p>
                <p>
                  <span className="faq_number">02</span> Fill in every detail
                  (either for buy airtime/data) and click on proceed.
                </p>
                <img className="faq_phons" src={Airtime2} alt="" />
                <img className="faq_phons" src={Airtime4} alt="" />
              </div>
            </div>

            <div data-accordion-item>
              <input type="checkbox" id="panel-17" />
              <label for="panel-17" data-accordion-label>
                How do I pay for my orders with OmniPay?
              </label>
              <div data-accordion-panel>
                <p>
                  <span className="faq_number">01</span> Click on the{" "}
                  <strong>Order</strong> button to view your order(s)
                </p>
                <img className="faq_phons" src={OmniPay_Payment1} alt="" />
                <p>
                  <span className="faq_number">02</span> Complete the
                  transaction with your OmniPay wallet(You can combine your
                  wallet, PayLater, Omoni points balance to pay for your
                  order(s)) and/or Cash.
                </p>
                <img className="faq_phons" src={OmniPay_Payment3} alt="" />
                <p>
                  <span className="faq_number">03</span> Input your Pin code to
                  complete the transaction.
                </p>
                <img className="faq_phons" src={OmniPay_Payment5} alt="" />
              </div>
            </div>
          </section>
        </div>
      </section>

      <Footer />
      <SignUpModal />
    </div>
  );
}

export default Faq;
