import "./Footer-Style.css";
import Logo from "../../../assets/images/light-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_row">
          <div className="col-footer_about">
            <img src={Logo} className="footer-logo" alt="" />
            <p>
              OmniBiz improves the distribution process from manufacturer’s
              factory to the retailers shelve by creating a technology driven
              FMCG platform.
            </p>
            <div className="social_links">
              <a
                href="https://www.facebook.com/omnibizafrica"
                rel="noreferrer"
                target="_blank"
              >
                <FaFacebook />{" "}
              </a>
              <a
                href="https://twitter.com/omnibizafrica"
                rel="noreferrer"
                target="_blank"
              >
                <FaTwitter />{" "}
              </a>
              <a
                href="https://www.instagram.com/omnibizafrica/"
                rel="noreferrer"
                target="_blank"
              >
                <FaInstagram />{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/omnibiz-africa"
                rel="noreferrer"
                target="_blank"
              >
                <FaLinkedin />{" "}
              </a>
              <a
                href="https://www.youtube.com/channel/UCbQgTtFNs-DCD-rJxoPMJkg/videos"
                rel="noreferrer"
                target="_blank"
              >
                <FaYoutube />{" "}
              </a>
            </div>
          </div>
          <div className="col-quick-links">
            <h3>My Company</h3>
            <div className="links-master">
              <Link to="/about-us"> About</Link>
              <Link to="/locate-retailers"> Locate Retailers</Link>
              <Link to="/faq"> FAQs</Link>
              <Link to="/contact-us"> Contact us</Link>
              <a
                href="https://blog.omnibiz.com/"
                rel="noreferrer"
                target="_blank"
              >
                Blogs
              </a>
            </div>
          </div>
          <div className="col-quick-links">
            <h3>Solutions</h3>
            <div className="links-master">
              <Link to="/retailers">Retailers</Link>
              <Link to="/manufacturers">Manufacturers</Link>
              <Link to="/distributors">Distributors</Link>
              <Link to="/logistics">Logistics</Link>
            </div>
          </div>
          <div className="col-quick-links mobile">
            <h3>OmniBiz App</h3>
            <a
              href="https://app.omnibiz.com/refer"
              target="_blank"
              rel="noreferrer"
              className="download_app"
            >
              Download the app <FontAwesomeIcon icon={faArrowRight} />
            </a>
          </div>
          <div className="col-quick-links desktop ">
            <h3>OmniBiz App</h3>
            <a
              href="https://play.google.com/store/apps/details?id=com.omnibiz.retail&hl=en_IN&gl=US"
              rel="noreferrer"
              target="_blank"
              className="download_app"
            >
              Download the app <FontAwesomeIcon icon={faArrowRight} />
            </a>
          </div>
        </div>
        <div className="coyright_row">
          © 2022 OmniBiz. All rights reserved.
          <Link to="/terms&conditions">Terms & Conditions</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
