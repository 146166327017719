import "./HeaderStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Header({ logo }) {
  return (
    <div>
      <nav className="navbar backdrop">
        <div className="navbar-container container">
          <input type="checkbox" name="" id="" />
          <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
          <ul className="menu-items">
            <li>
              <Link to="/about-us">About us</Link>
            </li>
            <li className="dropdown">
              <a href="#">
                Solutions{" "}
                <i>
                  <FontAwesomeIcon icon={faAngleDown} />
                </i>
              </a>
              <ul className="dropdown-nav">
                <li>
                  <Link to="/retailers">Retailers</Link>
                </li>
                <li>
                  <Link to="/manufacturers">Manufacturers</Link>
                </li>
                <li>
                  <Link to="/distributors">Distributors</Link>
                </li>
                <li>
                  <Link to="/logistics">Logistics</Link>
                </li>
              </ul>
            </li>

            <ul className="mobile_optional">
              <li className="mobileSolution_hdng">Solutions</li>
              <li>
                <Link to="/retailers">Retailers</Link>
              </li>
              <li>
                <Link to="/manufacturers">Manufacturers</Link>
              </li>
              <li>
                <Link to="/distributors">Distributors</Link>
              </li>
              <li>
                <Link to="/logistics">Logistics</Link>
              </li>
            </ul>

            <li>
              <Link to="/contact-us" className="login_btn">
                Contact us <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </li>
            <li>
              <a href="#signup" className="login_btn">
                Sign up Now <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </li>
          </ul>
          <Link to="/" className="brand-logo">
            <img src={logo} alt="" />
          </Link>
        </div>
      </nav>

      <div style={{ height: "75px" }}></div>
    </div>
  );
}

export default Header;
