import React, { useEffect } from "react";
import "./RetailersStyle.css";
import Header from "../../components/bloks/header/Header";
import Footer from "../../components/bloks/footer/Footer";
import OuterBanner from "../../components/outer-page-banner/OuterBanner";
import BannerCard from "../../components/banner-card/BannerCard";
import Steper from "../../components/steper/Steper";
import SteperImage from "../../components/steper/SteperImage";
import Distribution from "../../components/distribution/Distribution";
import AppInstall from "../../components/app-install/AppInstall";
import OmnibizSolutions from "../../components/omnibiz-solutions/OmnibizSolutions";

function Retailers() {
  useEffect(() => {
    document.title = "Retailers";
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div>
      <Header logo={"./images/logo.png"} />
      <OuterBanner backgroundImg={"url(images/retailers.png)"} />

      <BannerCard
        backgroundColor={"linear-gradient(to right, #f95b2f, #f44d21)"}
        icon={"./images/chimni.svg"}
        forOmni={"OmniBiz for"}
        heading={"Retailers"}
        describe={
          "OmniBiz makes it easy for you to buy a wide range of products at no extra cost."
        }
        button={"Sign up now"}
        videoPlay={"none"}
      />

      <section className="steper_sec">
        <div className="container">
          <div className="steper_row">
            <div className="steper_grid AAA">
              <SteperImage image={"/images/endspd.png"} />
            </div>
            <div className="steper_grid BBB">
              <Steper
                title={"EASE & SPEED"}
                heading={"A simpler and smarter way to do business"}
                description={
                  "OmniBiz makes it easy for retailers to restock their inventory in record time and at no extra costs."
                }
              />
            </div>
          </div>

          <div className="steper_row">
            <div className="steper_grid BBB">
              <Steper
                title={"WIDE INVENTORY"}
                heading={"Gain access to broad range of products"}
                description={
                  "Get access to a wide range of products from your favourite brands in one convenient place."
                }
              />
            </div>
            <div className="steper_grid AAA">
              <SteperImage image={"/images/wideinvent.png"} />
            </div>
          </div>

          <div className="steper_row">
            <div className="steper_grid AAA">
              <SteperImage image={"/images/paylater.png"} />
            </div>
            <div className="steper_grid BBB">
              <Steper
                title={"BUY NOW, PAY LATER"}
                heading={"Do even more with your Credit option"}
                description={
                  "Never run out of stock because you are low on cash. With OmniBiz, you can buy now and pay later."
                }
              />
            </div>
          </div>
        </div>
      </section>

      <AppInstall mobileview={"./images/new-app-install.png"} />
      <Distribution />

      <section className="over_auto solutions_row">
        <div className="solutions_item3colom">
          <OmnibizSolutions
            image={"images/manufactur.png"}
            backgroundColor={"rgb(231 38 53 / 75%)"}
            solutionsfor={"OmniBiz for"}
            title={"Manufacturers"}
            seebtn={"/manufacturers"}
          />
        </div>
        <div className="solutions_item3colom">
          <OmnibizSolutions
            image={"images/distributors-bg.png"}
            solutionsfor={"OmniBiz for"}
            backgroundColor={"rgb(1 16 80 / 80%)"}
            title={"Distributors"}
            seebtn={"/distributors"}
          />
        </div>

        <div className="solutions_item3colom">
          <OmnibizSolutions
            image={"images/logistics-solution.png"}
            backgroundColor={"rgb(109 29 96 / 80%)"}
            solutionsfor={"OmniBiz for"}
            title={"Logistics"}
            seebtn={"/logistics"}
          />
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Retailers;
